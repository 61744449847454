import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralService } from '../../services/general.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {

 // @Input() sidebarOpen: boolean | undefined = false;
  user: any;
  $hiddenSidebar?: Observable<boolean>;
  exisToken: any;
  tokenStorage: any;
  roles: string[] = [];
  isAdmin = false;
  isReceptionist = false;
  isFullDayDropdownOpen = false;
  isReservationsDropdownOpen = false;

  constructor(
    private loginService: LoginService,
    public generalService: GeneralService
  ) {}

  ngOnInit(): void {

    this.exisToken = this.generalService.get('token');
    this.$hiddenSidebar = this.generalService.getStatusActivitySidebar();
    this.loginService.userSubject.subscribe((res) => {
      this.user = res;
      this.exisToken = this.generalService.get('token');
      if (this.exisToken) {
        const decodedToken: any = this.decodeJWT(this.exisToken); 
        this.roles = decodedToken.roles.map((role: any) => role.authority);
        this.isAdmin = this.roles.includes('ROLE_SUPER_ADMIN');
        this.isReceptionist = this.roles.includes('ROLE_RECEPTIONIST');
      }
    });

    this.$hiddenSidebar = this.generalService.getStatusActivitySidebar();

  }

  decodeJWT(token: string): any {
    return JSON.parse(atob(token.split('.')[1]));
  }

  getTokenStorage() {
    this.tokenStorage = this.generalService.get('token');
  }

  closeSidebar() {
    // if (this.sidebarOpen !== undefined) {
    //   this.sidebarOpen = false;
    // }
  }

  toggleFullDayDropdown() {
    this.isFullDayDropdownOpen = !this.isFullDayDropdownOpen;
    this.isReservationsDropdownOpen = false;
  }

  toggleReservationsDropdown() {
    this.isReservationsDropdownOpen = !this.isReservationsDropdownOpen;
    this.isFullDayDropdownOpen = false; 
  }
}
