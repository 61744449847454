<div [class.hidden]="$hiddenSidebar | async" class="sidebar-wrapper" *ngIf="exisToken">
  <div class="sidebar">
    <div class="sidebar-header">
      <button class="close-button" (click)="closeSidebar()">
        <i class="pi pi-times"></i>
      </button>
    </div>
    <div class="sidebar-content">
      <ul>
        <!-- ADMIN -->
        <!-- <li *ngIf="isAdmin"><a href="/construction" routerLinkActive="active-link">
            Sistema de fidelización </a>
        </li> -->
        <li *ngIf="isAdmin"><a [routerLink]="['/manager/loyalty-system']" routerLinkActive="active-link">
            Sistema de fidelización </a>
        </li>
        <li *ngIf="isAdmin"><a [routerLink]="['/manager/reservation-manager']" routerLinkActive="active-link">
            Gestión de Reservas </a>
        </li>
        <li *ngIf="isAdmin"><a [routerLink]="['/manager/apartment-manager']" routerLinkActive="active-link">
            Gestión de Apartamentos </a>
        </li>

        <li *ngIf="isAdmin" class="dropdown">
          <a href="javascript:void(0)" (click)="toggleFullDayDropdown()" class="dropdown-toggle" role="button" aria-haspopup="true" aria-expanded="false">
            Gestor de FullDay
          </a>
          <ul *ngIf="isFullDayDropdownOpen" class="dropdown-menu">
            <li><a [routerLink]="['/manager/input-fullday-manager']" routerLinkActive="active-link">Solo Entrada / Todo Incluido</a></li>
            <li><a [routerLink]="['/manager//fullday-payment-manager']" routerLinkActive="active-link">Gestor de pagos Fullday</a></li>
          </ul>
        </li>
      
        <li *ngIf="isAdmin"><a [routerLink]="['/manager/payment-manager']" routerLinkActive="active-link">
            Gestor de pagos </a>
        </li>
        <li *ngIf="isAdmin"><a [routerLink]="['/manager/service-manager']" routerLinkActive="active-link">
            Gestor de servicios </a>
        </li>

        <li *ngIf="isAdmin"><a [routerLink]="['/manager/restaurant-manager']" routerLinkActive="active-link">
          Gestor de Restaurante </a>
        </li>

        <li *ngIf="isAdmin"><a [routerLink]="['/manager/user-manager']" routerLinkActive="active-link">
            Gestor de Usuarios </a>
        </li>
<!--         <li *ngIf="isAdmin"><a href="/construction" routerLinkActive="active-link">Gestor de paquetes</a></li>
 -->        <li *ngIf="isAdmin"><a [routerLink]="['/manager/promoter-manager']" routerLinkActive="active-link">Gestor de
            promotores</a></li>
        <li *ngIf="isAdmin"><a [routerLink]="['/manager/report-manager']" routerLinkActive="active-link">
            Gestor de Reportes </a>
        </li>

        <li *ngIf="isAdmin"> <a [routerLink]="['/manager/collections-manager']" routerLinkActive="active-link">
             Gestor de Cobranzas  </a> 
        </li>


        <!-- RECEPTIONIST -->
        <li><a [routerLink]="['/manager/dashboard-manager']" routerLinkActive="active-link">
            Dashboard </a>
        </li>
        <!--<li><a [routerLink]="['/manager/bookings-manager']" routerLinkActive="active-link">
            Reservas </a>
        </li>-->

        <li class="dropdown">
          <a href="javascript:void(0)" (click)="toggleReservationsDropdown()" class="dropdown-toggle" role="button" aria-haspopup="true" aria-expanded="false">
            Reservas
          </a>
          <ul *ngIf="isReservationsDropdownOpen" class="dropdown-menu">
            <li><a [routerLink]="['/manager/bookings-manager']" routerLinkActive="active-link">ALOJAMIENTO</a></li>
            <li><a [routerLink]="['/manager/booking-fullday-manager']" routerLinkActive="active-link">FULLDAY</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>